.logo {
  height: 50px;
  width: auto;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu {
  margin-top: 0;
}
/* .navbar-toggler .icon-bar {
  margin: 7px;
  display: block;
  width: 22px;
  height: 1px;
  background-color: #cccccc;
  border-radius: 1px;
} */
