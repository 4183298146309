$primary: #00695c;
$secondary: #4db6ac;
$success: #1b5e20;
$error: #dd2c00;
$info: #0277bd;
$warning: #ffd600;
$danger: #dd2c00;
$light: #ffffff;
$dark: #000000;
$text-muted: #9e9e9e;

//custom color to be mapped
$accent: #da6d25;
$list-primary: #ffffff;
$list-secondary: #e0f2f1;

// this is used to map colors. change accordingly.
$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  error: $error,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
  text-muted: $text-muted,
  // add any additional color below
  list-primary: $list-primary,
  // now bg-accent,btn-accent,etc.. can be called
  list-secondary: $list-secondary,
);

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
